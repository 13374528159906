import { Controller } from "@hotwired/stimulus"
import '@popperjs/core';
import { TempusDominus } from '@eonasdan/tempus-dominus/dist/js/tempus-dominus';
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus';

const datetimepickerConfig = {
  display: {
    components: {
      decades: false,
      year: true,
      month: true,
      date: true,
      hours: true,
      minutes: true,
      seconds: true,
      useTwentyfourHour: true,
    }
  },
  localization: {
    locale: 'es'
  }
}

// Connects to data-controller="datetimepicker"
export default class extends Controller {
  static values = {
    readonly: Boolean
  }

  connect() {
    const td = new TempusDominus(this.element, datetimepickerConfig)
    if (this.readonlyValue) td.disable();
  }
}
