import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="salvedad"
export default class extends Controller {
  static targets = ["fecha", "hora", "modal", "salvedadForm", "salvedadModal"]

  submitForm(e) {
    const fechaValue = this.fechaTarget.value;
    const horaValue = this.horaTarget.value;

    const [day, month, year] = fechaValue.split("/");
    const [hour, minute, second] = horaValue.split(":");

    const selectedDateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
    const currentDateTime = new Date();

    const timeDifference = (currentDateTime - selectedDateTime) / (60 * 60 * 1000);

    if (timeDifference >= 24) {
      e.preventDefault();

      this.modal = new bootstrap.Modal(this.modalTarget, { backdrop: 'static', keyboard: true })
      this.modal.show()
    }
  }

  submitSalvedad(e) {
    this.salvedadFormTarget.value = this.salvedadModalTarget.value;
    this.modal.hide();
  }

  showModal(e) {
    e.preventDefault();

    this.modal = new bootstrap.Modal(this.modalTarget, { backdrop: 'static', keyboard: true })
    this.modal.show()
  }
}
