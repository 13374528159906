import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="buscar-reportes"
export default class extends Controller {
  static targets = ["desde", "hasta"]
  static values = {
    url: String,
    registro: Number,
    processingReport: Boolean
  }

  connect() {
    this.processingReportValue = Boolean(this.data.get("processingReport"));
  }

  imprimir(e) {
    e.preventDefault()

    get(`${this.urlValue}?registro=${this.registroValue}&start_date=${this.desdeTarget.value}&end_date=${this.hastaTarget.value}`, { responseKind: 'turbo-stream' })
  }

  imprimirTodo(e) {
    console.log("click", this.processingReportValue)
    e.preventDefault()

    const link = e.currentTarget;

    if (this.processingReportValue == false) {
      link.setAttribute('disabled', true);
      link.classList.add('disabled');

      get(`${this.urlValue}?registro=${this.registroValue}`, { responseKind: 'turbo-stream' })
    } else {
      link.removeAttribute('disabled');
      link.classList.remove('disabled');
    }
  }
}
