// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Turbo } from '@hotwired/turbo-rails'
window.Turbo = Turbo
import * as ActiveStorage from "@rails/activestorage"
import 'channels'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
import 'admin-lte/dist/js/adminlte'
import '../src/components'
import '../controllers'

Rails.start()
ActiveStorage.start()
