import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="archivos-evoluciones-medicas"
export default class extends Controller {
  static targets = ["showArchivos", "section", "archivoId"]

  connect() {
    if(this.showArchivosTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    }
  }

  toggleSection(e) {
    e.preventDefault()

    if (this.showArchivosTarget.checked) {
      this.sectionTarget.classList.remove('d-none')
    } else {
      this.sectionTarget.classList.add('d-none')
    }
  }
}

