import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="speech"
export default class extends Controller {
  static targets = ["evolucion", "button"];

  connect() {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Web Speech API no es compatible con este navegador.");
      this.buttonTarget.disabled = true;
    } else {
      this.recognition = new webkitSpeechRecognition();
      this.recognition.lang = "es-ES";
      this.recognition.interimResults = false;
      this.recognition.continuous = true;
  
      this.recognition.onresult = (event) => {
        const result = event.results[event.results.length - 1][0].transcript;
        this.evolucionTarget.value += result + " ";
      };
  
      this.recognition.onerror = (event) => {
        console.error("Error en el reconocimiento de voz:", event.error);
      };
  
      this.recognition.onend = () => {
        if (this.isListening) {
          this.recognition.start(); 
        }
      };
  
      this.isListening = false;
    }
  }

  startRecognition() {
    if (this.recognition) {
      if (this.isListening) {
        this.recognition.stop();
        this.isListening = false;
        this.buttonTarget.style.color = '';
      } else {
        this.recognition.start();
        this.isListening = true;
        this.buttonTarget.style.color = 'red';
      }
    }
  }
}
